import React from 'react';
import { Box, Image } from '@mentimeter/ragnar-ui';
import NotFoundWordCloud from '../images/Wordcloud_404.svg';
import { Button } from '../ui/actions';
import { Limit, Section, Wide } from '../ui/layout';
import { H1, P } from '../ui/typography';
import Page from './Page';

export function NotFound() {
  return (
    <Error title="Page not found">
      <Wide width="90vw" mb="space8" alignItems="center">
        <Image src={NotFoundWordCloud.src} alt="404 word cloud" width="100%" />
      </Wide>
      <H1 textAlign="center" fontWeight="bold">
        You’ve found our 404 Word Cloud
      </H1>
      <P textAlign="center">
        Unfortunately, we couldn&#39;t find the page you&#39;re looking for!
        Head to our homepage by clicking the button below.
      </P>
    </Error>
  );
}

export function GeneralError() {
  return (
    <Error title="Something went wrong">
      <H1 textAlign="center">Something went wrong</H1>
      <P textAlign="center">
        Please try to refresh the page or contact our support.
      </P>
    </Error>
  );
}

const Error = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Page>
      <Section minHeight={['40vh', '80vh']} justifyContent="center" mb="space0">
        <Limit alignItems="center">
          {children}
          <Box flexDirection={['column', 'row']} alignItems="center">
            <Button
              variant="secondary"
              href="mailto:hello@mentimeter.com"
              m="space1"
            >
              Email us
            </Button>
            <Button variant="primary" href="/" m="space1">
              Homepage
            </Button>
          </Box>
        </Limit>
      </Section>
    </Page>
  );
};
